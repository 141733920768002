import moment from "moment";
import base64 from 'base-64';
import utf8 from 'utf8';

export function convertToBase64Encode(varString) {
	if (isNullOrEmptySpace(varString))
		return null
	var bytes = utf8.encode(varString);
	var encoded = base64.encode(bytes);
	return encoded;
}

export function convertToBase64Decode(varString) {
	var bytes = base64.decode(varString);
	var result = utf8.decode(bytes);
	return result;
}

export function StringToXMLDom(string) {
	var xmlDoc = null;
	if (window.DOMParser) {
		var parser = new DOMParser();
		xmlDoc = parser.parseFromString(string, "text/xml");
	}
	else // Internet Explorer
	{
		xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
		xmlDoc.async = "false";
		xmlDoc.loadXML(string);
	}
	return xmlDoc;
}

export function replaceParameter(obj, value, type) {
	obj = JSON.stringify(obj);
	obj = obj.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	type = type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	value = JSON.parse(JSON.stringify(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())

	var tags = obj.match(/\<\|(.+?)\|\>/g);
	if (isNullOrEmptySpace(tags))
		return JSON.parse(obj);

	tags.map(function (tag) {
		var tagclean = String(tag.match(/\w*\.\w*/gi))
		var tags = tagclean.split('.')
		if (tags.length > 1 && !isNullOrEmptySpace(tags[0]) && !isNullOrEmptySpace(tags[1])
			&& String(tags[0]).toLowerCase() === String(type).toLowerCase() && !isNullOrEmptySpace(value[tags[1].toLowerCase()])) {
			obj = obj.replace(tag, value[tags[1].toLowerCase()]);
		}
	})
	return JSON.parse(obj)
}

export function isNullOrEmptySpace_old(obj) {
	var objSource = obj;
	obj = String(obj).toLowerCase();
	var result = false;
	if (obj === "undefined" || obj === undefined || obj === "null" || obj == null)
		return true;
	else if (obj.trim() === "")
		return true;
	else if (obj === "{}")
		return true;

	if (JSON.stringify(objSource) === "{}" || JSON.stringify(objSource) === "[]")
		return true;

	if (!result && objSource.constructor == Object && JSON.stringify(objSource) === "{}")
		return true;


	return result;
}

export function isNullOrEmptySpace(obj) {
	var result = true;
	if (obj !== undefined && obj !== null) {
		if (obj.constructor.name === "String" && obj.trim() !== "" && obj.trim().length > 0)
			return false
		if (obj.constructor.name === "Object" && obj != {} && JSON.stringify(obj) !== "{}")
			return false
		if (obj.constructor.name === "Array" && obj.length > 0)
			return false
		if (obj.constructor.name === "Boolean")
			return false
		if (obj.constructor.name === "Number")
			return false
		if (JSON.stringify(obj).trim() !== "" && JSON.stringify(obj) !== "{}" && JSON.stringify(obj).length > 2)
			return false
		if (JSON.stringify(obj).trim() !== "" && JSON.stringify(obj) !== "[]" && JSON.stringify(obj).length > 2)
			return false
	}
	return result;
}

export function refresh() {
	window.location.reload();
}

export function convertJSONToArray(json) {
	if (isNullOrEmptySpace(json))
		return []
	var result = Object.keys(json).map(k => {
		var rVal = "";
		var rKey = k;

		if (json[k].constructor == Array) {
			rVal = "Lista com " + json[k].length + " itens"
		}
		else if (json[k].constructor == Object && Object.keys(json[k]).length > 0) {
			var listObjKey = Object.keys(json[k]);
			rKey = listObjKey[0];
			if (json[k][listObjKey[0]].constructor == Array) {
				rVal = "Lista com " + (json[k][listObjKey[0]]).length + " itens";
			}
			else {
				rVal = json[k][listObjKey[0]]
			}

		}
		else
			rVal = json[k]

		return { key: rKey, value: rVal }
	})
	return result
}

export function throwError(error) {
	//throw new Error(error)
	throw Error(error)
}

export function rgba2hex(obj) {
	let orig = `rgba(${obj.r},${obj.g}, ${obj.b}, ${obj.a})`
	var a, isPercent,
		rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
		alpha = (rgb && rgb[4] || "").trim(),
		hex = rgb ?
			(rgb[1] | 1 << 8).toString(16).slice(1) +
			(rgb[2] | 1 << 8).toString(16).slice(1) +
			(rgb[3] | 1 << 8).toString(16).slice(1) : orig;

	if (alpha !== "") {
		a = alpha;
	} else {
		a = 1;
	}
	// multiply before convert to HEX
	a = ((a * 255) | 1 << 8).toString(16).slice(1)
	hex = hex + a;

	return "#" + hex;
}

export function paginationComponentOptions() {
	return {
		rowsPerPageText: "Registro por página",
		rangeSeparatorText: "de",
		selectAllRowsItem: true,
		selectAllRowsItemText: "Todos",
	}
}

export async function getLocation() {
	const getCoords = async () => {
		const pos = await new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject);
		});

		return {
			lng: pos.coords.longitude,
			lat: pos.coords.latitude,
			status: "OK"
		};
	};
	const coords = await getCoords().catch(error => {
		let erro = ""
		switch (error.code) {
			case error.PERMISSION_DENIED:
				erro = "Usuário rejeitou a solicitação de Geolocalização."
				break;
			case error.POSITION_UNAVAILABLE:
				erro = "Localização indisponível."
				break;
			case error.TIMEOUT:
				erro = "O tempo da requisição expirou."
				break;
			case error.UNKNOWN_ERROR:
				erro = "Algum erro desconhecido aconteceu."
				break;
		}
		return {
			status: "ERROR",
			error: erro
		}
	});
	return coords
}

export function getDisntanceFormPonts(position1, position2) {
	var deg2rad = function (deg) { return deg * (Math.PI / 180); },
		R = 6371,
		dLat = deg2rad(position2.lat - position1.lat),
		dLng = deg2rad(position2.lng - position1.lng),
		a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
			+ Math.cos(deg2rad(position1.lat))
			* Math.cos(deg2rad(position1.lat))
			* Math.sin(dLng / 2) * Math.sin(dLng / 2),
		c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	return parseInt((R * c * 1000).toFixed());
}


export const getTimeChat = (date) => {
	if (isToday(date))
		return moment(date).format('HH:mm')
	else if (isYesterday(date))
		return "ontem"
	else if (isMonth(date))
		return moment(date).format('DD-MM')
	else
		return moment(date).format('DD/MM/yyyy')
};


const isToday = (someDate) => {
	const today = new Date()
	return someDate.getDate() == today.getDate() &&
		someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
}

const isYesterday = (someDate) => {
	const today = new Date()
	return someDate.getDate() == today.getDate() - 1 &&
		someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
}

const isMonth = (someDate) => {
	const today = new Date()
	return someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
}
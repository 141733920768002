import React from "react";
import { Navbar } from "reactstrap";
import { getUser } from "../data/SessionVar.jsx";
import { isNullOrEmptySpace, convertToBase64Encode } from '../framework/Functions/AstroNetUtility';
class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            valueShow: "navbar sidebar sidebar-offcanvas"
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.valueMenuShow !== nextProps.valueMenuShow) {
            if (nextProps.valueMenuShow) {
                this.setState({
                    valueShow: "navbar sidebar sidebar-offcanvas.active"
                });
            } else {
                this.setState({
                    valueShow: "navbar sidebar sidebar-offcanvas"
                });
            }
        }
    }

    renderMenuItem() {
        var user = getUser()
        if (isNullOrEmptySpace(user.ListMenu) || user.ListMenu.length == 0)
            return
        return (<>

            {user.ListMenu.map((x, i) => {
                if (x.Url === "*") {
                    return (
                        <li key={i} className="nav-item nav-category">{x.Name}</li>
                    )
                }
                else if (x.Url.includes("http")) {
                    return (
                        <li key={i} className="nav-item">
                            <a className="nav-link" href={"/admin/formframe/" + convertToBase64Encode(x.Url)}>
                                <i className="menu-icon typcn typcn-document-text"></i>
                                <span className="menu-title">{x.Name}</span>
                            </a>
                        </li>
                    )
                }
                else {
                    return (
                        <li key={i} className="nav-item">
                            <a className="nav-link" href={x.Url}>
                                <i className="menu-icon typcn typcn-document-text"></i>
                                <span className="menu-title">{x.Name}</span>
                            </a>
                        </li>
                    )
                }
            })}
        </>
        )
    }

    render() {
        return (
            <Navbar className={this.state.valueShow} id="sidebar" hidden={!this.props.valueMenuShow}>
                <ul className="nav">
                    {this.renderMenuItem()}
                </ul>
            </Navbar>
        )
    }
}
export default SideBar

import React from "react";
import { Col, Row, Navbar, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import MaterialIcon from 'material-icons-react';
import PropTypes from 'prop-types';
import { getUser, removeToken, redirectHelp, redirectHelpAPI, getUrlBackEnd, getUrlFrontEnd } from "../data/SessionVar.jsx";
import { convertToBase64Encode, isNullOrEmptySpace } from '../framework/Functions/AstroNetUtility'
import { ReactQrcode } from 'reactjs-qrcode-generator'
import logoSvg from "../../assets/logo.svg"
import logoAstroSvg from "../../assets/logoastro.svg"
class TopBar extends React.Component {
    static propTypes = {
        getSelectedMenu: PropTypes.func.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            valueSearch: "",
            dropdownOpen: false,
            valueMenuProfile: "",
            valueMenu: true,
            title: ""
        }
        this.toggle = this.toggle.bind(this);
        this.getSelectedSearch = this.getSelectedSearch.bind(this);
        this.onClickMenu = this.onClickMenu.bind(this);
        this.redirectToUser = this.redirectToUser.bind(this);
        this.redirectToExit = this.redirectToExit.bind(this);

    }

    getValueMenu(value) {
        const { getSelectedMenu } = this.props;
        getSelectedMenu({
            value: value
        });
    };

    onClickMenu() {
        this.setState({ valueMenu: !this.state.valueMenu });
        this.getValueMenu(this.state.valueMenu);
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    getSelectedSearch(event) {
        this.setState({ valueSearch: event.value })
    }

    redirectToUser() {
        window.location.href = "/admin/userhandle/my"
    }

    redirectToExit() {
        removeToken()
        window.location.href = "/login"
    }
    componentDidMount() {
        let nameTitle = !isNullOrEmptySpace(getUser()) && !isNullOrEmptySpace(getUser().Client) && !isNullOrEmptySpace(getUser().Client.name) ? getUser().Client.name : "";
        this.setState({ title: nameTitle })
    }
    render() {
        let user = getUser()

        user.BackEnd = getUrlBackEnd()
        user.FrontEnd = getUrlFrontEnd()
        delete user.ListMenu
        delete user.Group
        delete user.UserGroup
        delete user.ListDepartment
        delete user.ListRole
        delete user.Sequence
        delete user.Status
        delete user.Password
        delete user.Name
        delete user.Client
        let userCode = convertToBase64Encode(JSON.stringify(user))

        return (
            <Navbar className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row" >
                <Navbar className="navbar-brand-wrapper d-flex justify-content-center" onClick={() => this.onClickMenu()}>
                    <img src={logoSvg} alt="logo" style={{ height: "50px" }} />
                </Navbar>
                <Navbar className="navbar-menu-wrapper navbar-toggler-right ">
                    <div className="justify-content-center">
                        {isNullOrEmptySpace(this.state.title) ?
                            <img style={{ width: "35%" }} src={logoAstroSvg} alt="logo" /> :
                            <div style={{ fontSize: "30px" }}><b>{this.state.title}</b></div>
                        }
                    </div>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret className="color-background-transparent" style={{ color: "#fff" }}>
                            <MaterialIcon icon="person" color="#fff" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>
                                <div className="dropdown-header text-center">
                                    <p className="mb-1 mt-3 font-weight-semibold">{getUser().name}</p>
                                    <p className="font-weight-light text-muted mb-0">{getUser().email}</p>
                                    <p>
                                        <ReactQrcode qrvalue={userCode} size={150} level={1} />
                                    </p>

                                </div>
                            </DropdownItem>
                            <DropdownItem className="dropdown-item"
                                onClick={this.redirectToUser}>Meu Perfil</DropdownItem>
                            <DropdownItem className="dropdown-item"
                                onClick={() => { redirectHelp() }}>Manual</DropdownItem>
                            <DropdownItem className="dropdown-item"
                                onClick={() => { redirectHelpAPI() }}>Manual APIs</DropdownItem>
                            <DropdownItem className="dropdown-item"
                                onClick={() => this.redirectToExit()}>Sair<i className="dropdown-item-icon ti-power-off"></i></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Navbar>
            </Navbar>
        )
    }
}
export default TopBar
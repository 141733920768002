import React from "react";
import { Route, Switch } from "react-router-dom";
import { getTokenLogin, getUser, signalRConnection } from "../data/SessionVar.jsx";
import routes from "./../../routes.js";
import Load from "./PageProgress.jsx";
class Flow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        signalRConnection(getUser().User, getTokenLogin())
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/flow") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        return (
            <div className="main-panel-max">
                <div className="content-wrapper">
                    <React.Suspense fallback={<Load />}>
                        <Switch>
                            {this.getRoutes(routes)}
                        </Switch>
                    </React.Suspense>
                </div>
            </div>
        )
    }
}
export default Flow
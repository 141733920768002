import { ExecuteDataSourceSync } from "../framework/AstroNetREST";
import { isNullOrEmptySpace, convertToBase64Encode, rgba2hex, convertToBase64Decode } from "../framework/Functions/AstroNetUtility";
import * as signalR from "@microsoft/signalr"

//***************************************** */
var key = convertToBase64Encode(window.location.hostname)
export function getTokenLogin() {

    const getTokenAnonymous = () => {
        let obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + 'user/get/anonymous',
            DataSourceHeader: {
                'Content-Type': 'application/json',
            }
        }
        return ExecuteDataSourceSync(obj);
    }

    var token = window.sessionStorage.getItem(key + ".TOKEN_TEMP");
    if (isNullOrEmptySpace(token)) {
        token = getTokenAnonymous();
    }
    return token;


}

export function setToken(token) {
    window.sessionStorage.setItem(key + ".TOKEN_TEMP", token);

}

export function setUser(user) {
    window.sessionStorage.setItem(key + ".USER", JSON.stringify(user));
    setColor()
    getKeyMapBackEnd()
    getKeyAstroWhatsAppBackEnd()
}

export function setColor() {
    let user = getUser()
    if (user.Client != null && !user.Client.defaultScreen) {
        let c = user.Client.color
        setColorApp(c)
    }
}

export function setColorApp(c) {
    if (isNullOrEmptySpace(c))
        return;

    document.documentElement.style.setProperty("--cor-base-fundo", rgba2hex(c.corBaseFundo))

    document.documentElement.style.setProperty("--cor-menu-fundo", rgba2hex(c.corMenuFundo))
    document.documentElement.style.setProperty("--cor-menu-letra", rgba2hex(c.corMenuLetra))
    document.documentElement.style.setProperty("--cor-menu-icone", rgba2hex(c.corMenuIcone))
    document.documentElement.style.setProperty("--cor-menu-fundo-selecionado", rgba2hex(c.corMenuFundoSelecionado))
    document.documentElement.style.setProperty("--cor-menu-letra-selecionado", rgba2hex(c.corMenuLetraSelecionado))
    document.documentElement.style.setProperty("--cor-menu-icone-selecionado", rgba2hex(c.corMenuIconeSelecionado))

    document.documentElement.style.setProperty("--cor-menu-principal-letra", rgba2hex(c.corMenuPrincipalLetra))
    document.documentElement.style.setProperty("--cor-menu-principal-fundo", rgba2hex(c.corMenuPrincipalFundo))

    document.documentElement.style.setProperty("--cor-topo-fundo", rgba2hex(c.corTopoFundo))
    document.documentElement.style.setProperty("--cor-topo-letra", rgba2hex(c.corTopoLetra))

    document.documentElement.style.setProperty("--cor-btn-primary-fundo", rgba2hex(c.corBtnPrimaryFundo))
    document.documentElement.style.setProperty("--cor-btn-primary-borda", rgba2hex(c.corBtnPrimaryBorda))
    document.documentElement.style.setProperty("--cor-btn-primary-letra", rgba2hex(c.corBtnPrimaryLetra))

    document.documentElement.style.setProperty("--cor-btn-primary-fundo-selecionado", rgba2hex(c.corBtnPrimaryFundoSelecionado))
    document.documentElement.style.setProperty("--cor-btn-primary-borda-selecionado", rgba2hex(c.corBtnPrimaryBordaSelecionado))
    document.documentElement.style.setProperty("--cor-btn-primary-letra-selecionado", rgba2hex(c.corBtnPrimaryLetraSelecionado))
}

export function getUser() {
    if (isNullOrEmptySpace(window.sessionStorage.getItem(key + ".USER")))
        return {}
    else
        return JSON.parse(window.sessionStorage.getItem(key + ".USER"));
}

export function getPermission(type) {
    let user = getUser();
    if (isNullOrEmptySpace(user) ||
        isNullOrEmptySpace(user.UserGroup))
        return false;

    if (user.UserGroup.Master)
        return true;

    switch (type.toUpperCase()) {
        case "SENSITIVE":
            return !isNullOrEmptySpace(user.UserGroup.SensitiveData) && user.UserGroup.SensitiveData
        case "INSTANCE-OPENCLOSE":
            return !isNullOrEmptySpace(user.UserGroup.OpenCloseInstance) && user.UserGroup.OpenCloseInstance
        case "INSTANCE-REMOVE":
            return !isNullOrEmptySpace(user.UserGroup.RemoveInstance) && user.UserGroup.RemoveInstance
        case "INSTANCE-FORWARD":
            return !isNullOrEmptySpace(user.UserGroup.ForwardInstance) && user.UserGroup.ForwardInstance
        case "INSTANCE-EDIT":
            return true;
        default:
            return false;
    }

}

export function islogin(context) {
    if (!isNullOrEmptySpace(window.localStorage.getItem(key + ".msTPF"))) {
        window.sessionStorage.setItem(key + ".TOKEN_TEMP", window.localStorage.getItem(key + ".msTPF"));
    }
    if (isNullOrEmptySpace(window.sessionStorage.getItem(key + ".TOKEN_TEMP"))) {
        context.props.history.push("/login");
    }
}

export function removeToken() {
    window.sessionStorage.setItem(key + ".TOKEN_TEMP", "");
    window.localStorage.setItem(key + ".msTPF", "");
    window.sessionStorage.setItem(key + ".USER", "");
    window.sessionStorage.setItem(key + ".KEYMAP", "");
    window.sessionStorage.setItem(key + ".KEYASTROWHATSAPP", "");
}

//**************************************** */

export function getUrlFrontEnd() {
    return window.location.origin;
}

export function getUrlBackEnd() {
    return window.BASE_URL_API;
}

export function getContentJson() {
    return {
        headers: {
            'Content-Type': 'application/json'
        }
    }
}

export function redirectHelp() {
    window.open(window.BASE_URL_HELP, "_blank");
    //window.location.href = window.BASE_URL_HELP;
}

export function redirectHelpAPI() {
    window.open(window.BASE_URL_API, "_blank");
    //window.location.href = window.BASE_URL_API;
}


/************************** MAP ***********/

export function getKeyMapBackEnd() {

    const getkey = () => {
        let obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + 'generic/google/key',
            DataSourceHeader: {
                'Content-Type': 'application/json',
                Authorization: getTokenLogin(),
            }
        }
        return ExecuteDataSourceSync(obj);
    }

    var keyMap = getkey();
    if (!isNullOrEmptySpace(keyMap))
        window.sessionStorage.setItem(key + ".KEYMAP", convertToBase64Encode(keyMap));
    else
        window.sessionStorage.removeItem(key + ".KEYMAP");
}

export function getKeyMap() {
    var keyEncode = window.sessionStorage.getItem(key + ".KEYMAP");
    if (!isNullOrEmptySpace(keyEncode)) {
        return convertToBase64Decode(keyEncode)
    }
    return null
}

export function isMap() {
    return !isNullOrEmptySpace(getKeyMap())
}


/************************** AstroWhatsApp ***********/
export function getKeyAstroWhatsAppBackEnd() {
    const getkey = () => {
        let obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + 'generic/astrowhatsapp/key',
            DataSourceHeader: {
                'Content-Type': 'application/json',
                Authorization: getTokenLogin(),
            }
        }
        return ExecuteDataSourceSync(obj);
    }

    var whatsApp = getkey();
    if (!isNullOrEmptySpace(whatsApp))
        window.sessionStorage.setItem(key + ".KEYASTROWHATSAPP", convertToBase64Encode(JSON.stringify(whatsApp)));
    else
        window.sessionStorage.removeItem(key + ".KEYASTROWHATSAPP");
}

export function getKeyAstroWhatsApp() {
    var keyEncode = window.sessionStorage.getItem(key + ".KEYASTROWHATSAPP");
    if (!isNullOrEmptySpace(keyEncode)) {
        return JSON.parse(convertToBase64Decode(keyEncode))
    }
    return null
}

export function isAstroWhatsApp() {
    return !isNullOrEmptySpace(getKeyAstroWhatsApp())
}

/*********************** SignalR ********************/

export function signalRConnection(user, token) {
    window.BASE_CONNECTION_SIGNALR = new signalR.HubConnectionBuilder()
        .withUrl(getUrlBackEnd() + "signalr", {  // localhost from **AspNetCore3.1 service**
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            timeout:100000
        })
        .withAutomaticReconnect()
        .build();

    window.BASE_CONNECTION_SIGNALR.start().then(() => {
        let user = getUser().User
        let key = getTokenLogin()

        window.BASE_CONNECTION_SIGNALR.send("RegisterUser", user, key)

    }).catch(err => console.error(err))

    window.BASE_CONNECTION_SIGNALR.on("receiveRegister", (message) => {
        console.log(message);
    });

}

export function signalREvent() {
    return window.BASE_CONNECTION_SIGNALR
}
import React from 'react';
import { Button, Card, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';
import * as TiIcons from 'react-icons/ti';
import * as GoIcons from 'react-icons/go';
import * as FiIcons from 'react-icons/fi';
import * as GiIcons from 'react-icons/gi';
import { isNullOrEmptySpace } from './Functions/AstroNetUtility';

const allIcons = {
    ...FaIcons,
    ...IoIcons,
    ...MdIcons,
    ...TiIcons,
    ...GoIcons,
    ...FiIcons,
    ...GiIcons,
};

export const IconList = (props) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const [selected, setSelected] = React.useState('');
    const iconsArray = Object.keys(allIcons).map((icon) => ({ name: icon, IconComponent: allIcons[icon] }));

    const filteredIcons = iconsArray.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()));

    const iconsToRender = searchTerm.length > 4 ? filteredIcons : filteredIcons.slice(0, 85);

    const isSelected = (icon) => {
        return !isNullOrEmptySpace(selected) && selected === icon
    }

    const onClick = (icon) => {
        if (selected !== icon) {
            props.onSelected(icon)
            setSelected(icon)
        }
    }

    const renderSelected = () => {
        if (!isNullOrEmptySpace(props.value) && isNullOrEmptySpace(selected)) {
            const IconComponent = allIcons[props.value];

            return IconComponent ?
                <Card style={{ margin: "2px 2px 2px 2px", width: "90px" }}>
                    <Col className='center'
                        style={{ cursor: "pointer", background: "#535353" }}
                    >
                        <IconComponent
                            style={{ margin: "10px 2px 2px 2px", color: "#ffffff" }} size={30} />
                        <p style={{ color: "#ffffff" }}>
                            {props.value.length > 10 ? props.value.substring(0, 10) : props.value}
                        </p>
                    </Col>
                </Card>
                : null
        }
    }



    return (
        <>
            <Row>
                <Input type="text" value={searchTerm}
                    style={{ marginBottom: "10px" }}
                    onChange={(e) => setSearchTerm(e.target.value)} placeholder="Pesquisar ícone..." />
            </Row>
            <Row>
                {renderSelected()}
                {iconsToRender.map(({ name, IconComponent }) => {
                    if (!(!isNullOrEmptySpace(props.value) && name === props.value))
                        return <Card style={{ margin: "2px 2px 2px 2px", width: "90px" }}
                            onClick={() => onClick(name)} key={name}
                        >
                            <Col key={name} className='center'
                                style={{ cursor: "pointer", background: isSelected(name) ? "#535353" : "#ffffff" }}
                            >
                                <IconComponent
                                    style={{ margin: "10px 2px 2px 2px", color: isSelected(name) ? "#ffffff" : "black" }} size={30} />
                                <p style={{ color: isSelected(name) ? "#ffffff" : "black" }}>
                                    {name.length > 10 ? name.substring(0, 10) : name}
                                </p>
                            </Col>
                        </Card>
                }
                )}
            </Row>
        </>
    );
};

export const GalleryIconModel = (props) => {
    return (
        <Modal isOpen={props.isOpen} size="lg">
            <ModalHeader toggle={() => props.toggle()}>
                Galeria de Icones
            </ModalHeader>
            <ModalBody style={{ margin: "5px 5px 5px 20px" }} className='center'>
                <IconList {...props} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => props.toggle()}>Fechar</Button>
            </ModalFooter>
        </Modal>
    )
}


export const Icon = ({ iconName, ...props }) => {
    const IconComponent = allIcons[iconName];
    return IconComponent ? <IconComponent {...props} /> : null;
};


import React from "react";
import { Container } from "reactstrap";
class FooterBar extends React.Component {
  render() {
    return (
        <footer className="footer">
          <Container className="clearfix" fluid>
            <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2019 ASTRO SOLUÇÃO. All rights reserved.</span>
            <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-muted text-center">ASTROFLOW Solução em Automação - Versão:
            <i className="mdi mdi-heart text-danger">fV 1.0.0.0 - bV 1.0.0.1</i>
            </span>
          </Container>
        </footer>
    )
  }
}
export default FooterBar

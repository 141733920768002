import React from 'react';
import ReactDOM from 'react-dom';
import Admin from './components/layout/Admin.jsx';
import Expand from './components/layout/Expand';
import Login from './components/login/Login.jsx';
import Flow from './components/layout/Flow.jsx';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ErrorBoundary from "./components/form/Error/ErrorBoundary"

ReactDOM.render(
    <ErrorBoundary>
    <BrowserRouter>
        <Switch>
            <Route path="/flow" render={props => <Flow {...props} />} />
            <Route path="/expand" render={props => <Expand {...props} />} />
            <Route path="/admin" render={props => <Admin {...props} />} />
            <Route path="/login/:token/:redirect" render={props => <Login {...props} />} />
            <Route path="/login" render={props => <Login {...props} />} />            
            <Redirect from="/" to="/admin" />
        </Switch>
    </BrowserRouter>
    </ErrorBoundary>
    ,
    document.getElementById('root'));
//serviceWorker.unregister()

import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./../../routes.js";
import { islogin, signalRConnection } from "../data/SessionVar.jsx";
import { isNullOrEmptySpace } from "../framework/Functions/AstroNetUtility";
import { getUrlBackEnd, getTokenLogin, getUser, setUser } from "../data/SessionVar.jsx";
import { ExecuteDataSource } from '../framework/AstroNetREST';
import Load from "./PageProgress.jsx";

class Expand extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            valueMenuShow: false
        }
        this.getSelectedMenu = this.getSelectedMenu.bind(this)
        islogin(this)
        this.getUserLogin()
    }

    getUserLogin() {
        var obj = {
            DataSourceMethod: 'get',
            DataSourceUrl: getUrlBackEnd() + "user/get",
            DataSourceHeader: {
                'Content-Type': 'application/json',
                Authorization: getTokenLogin()
            }
        }
        var _this = this
        ExecuteDataSource(obj).then(function (response) {
            if (isNullOrEmptySpace(response) || isNullOrEmptySpace(response.Name)) {
                _this.props.history.push("/login");
            }
            setUser(response)
            signalRConnection(getUser().User, getTokenLogin())
        }).catch(function (error) {
            console.error(error)
            _this.props.history.push("/login");
        })


    }

    getSelectedMenu(event) {
        this.setState({ valueMenuShow: event.value })
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.layout === "/expand") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        if (!isNullOrEmptySpace(this.state.error))
            throw this.state.error;
        return (
                <div className="main-panel-max">
                    <div className="content-wrapper">
                    <React.Suspense fallback={<Load />}>
                            <Switch>
                                {this.getRoutes(routes)}
                            </Switch>
                        </React.Suspense>
                    </div>
                </div>
        )
    }
}
export default Expand